<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader" ref="myHeader_regisFormEdit">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerCerrar
              @cancelar="callAbandonar"
              colorIcon="rojoOscuro"
              v-if="faltanDatos && nivelActual == 1"
            />
            <headerBack @back="backLocal" v-else />
          </div>
          <div class="regisFormEditTitle title">
            {{ t("app.registroFormEdit.tit") }}
          </div>
        </div>
      </div>

      <!-- <div class="blur_sup" :style="blur_sup_regisFormEdit"></div> -->
      <blurComp
        class="blur_sup"
        :style="blur_sup_regisFormEdit"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contRegEdit"
        v-show="cont_visible"
        @scroll="onScroll"
        ref="contenido_regisFormEdit"
      >
        <div class="cont_300_basico ancho_acotado">
          <div
            class="body_regisFormEdit_class"
            v-if="listaElementosDomOrdenada.length > 0"
            ref="body_regisFormEdit"
          >
            <div
              v-for="(elemento, index) in listaElementosDomOrdenada"
              :key="index"
              :ref="elemento.variable"
            >
              <div
                class="regisFormEditInputCont"
                v-if="
                  (elemento.tipo == 'text' || elemento.tipo == 'number') &&
                  !elemento.big
                "
              >
                <inputBoxComp
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    t('configuracion.data.textos_errores.rut'),
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                class="regisFormEditInputCont"
                v-if="elemento.tipo == 'tel' && !elemento.big"
              >
                <input_telefonico
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    t('configuracion.data.textos_errores.rut'),
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                class="regisFormEditInputCont"
                v-if="
                  (elemento.tipo == 'text' ||
                    elemento.tipo == 'tel' ||
                    elemento.tipo == 'number') &&
                  elemento.big
                "
              >
                <inputBoxComp_big
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    t('configuracion.data.textos_errores.rut'),
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @call_explicacion="call_explicacion"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                class="regisFormEditInputCont"
                v-if="elemento.tipo == 'selectDir'"
              >
                <div v-for="(controlSelect, i) in elemento.elementos" :key="i">
                  <selectBox3
                    :elementosDom="controlSelect"
                    @llamarPoblarOpcionesDir="poblarOpcionesElementoDirLocal"
                    :indiceElementoDom="index"
                    :mostrarLabel="i % 2 == 0"
                    :mensajeError="
                      t('configuracion.data.textos_errores.campoObligatorio')
                    "
                    :disabled="false"
                    tabindex="0"
                    :error="controlSelect.tieneError"
                    @valida_basico_="valida_basico"
                  />
                </div>
              </div>

              <!-- ***** -->
              <div
                class="regisFormEditInputCont"
                v-if="
                  elemento.tipo == 'select' && elemento.elementos.length > 0
                "
              >
                <selectBasicoDinamicRemit
                  :elementosDom="elemento"
                  @llamarCambioElemento="cambioElementoLocal"
                  :indiceElementoDom="index"
                  :mostrarLabel="true"
                  :mensajeError="
                    t('configuracion.data.textos_errores.campoObligatorio')
                  "
                  :disabled="false"
                  tabindex="0"
                  :error="elemento.tieneError"
                  @valida_basico_="valida_basico"
                  :editando="true"
                />
              </div>
              <!-- **** -->

              <div
                class="regisFormEditInputCont"
                v-if="elemento.tipo == 'radioBtns'"
              >
                <hr class="regisFormEditHr" />

                <radioBtnsAzulComp
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @llamarCambioElemento="cambioElementoRadioBtnsLocal"
                  @call_explicacion="call_explicacion"
                />
              </div>
            </div>
          </div>
          <div class="regisFormEditActionBtn">
            <btnAction
              :textBtn="textBtn"
              :disabled="!btnActive"
              @action="continuarRegistro"
              colorBack="rojo"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="t('app.comunes.btns.completar_formulario')"
              @click="estadoBtn == 'inactive' ? mostrar_error() : false"
            />
            {{ respRemitenteModif.msg }}
          </div>
        </div>
      </div>

      <!-- <div class="blur_inf" :style="blur_inf_regisFormEdit"></div> -->
      <blurComp
        class="blur_inf"
        :style="blur_inf_regisFormEdit"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer" ref="footer_regisFormEdit">
        <div class="">
          <pasosGiro :paso="nivelActual" :totales="nivelesDinamico" />
        </div>
        <div class="footer_env_seguro">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, alertController } from "@ionic/vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import headerBack from "../components/generales/headerBack.vue";
import inputBoxComp from "../components/remitente/inputBoxDinCompRem.vue";
import inputBoxComp_big from "../components/remitente/inputBoxDinCompRem_big.vue";
import btnAction from "../components/generales/btnAction.vue";
import selectBox3 from "../components/remitente/selectBoxDinCompRem.vue";
import radioBtnsAzulComp from "../components/remitente/radioBtnsAzulCompRem.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import explicacion from "./modales/explicacion.vue";
import selectBasicoDinamicRemit from "../components/remitente/selectBasicoDinamicRemit.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import input_telefonico from "../components/remitente/input_telefonico.vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import estas_seguro from "./modales/estas_seguro.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "registroFormEdit",
  components: {
    headerBack,
    inputBoxComp,
    inputBoxComp_big,
    btnAction,
    selectBox3,
    radioBtnsAzulComp,
    pasosGiro,
    enviosSeguros,
    blurComp,
    selectBasicoDinamicRemit,
    screen_width_cont,
    input_telefonico,
    headerCerrar,
  },
  data() {
    return {
      editarDatos: true,
      niveles: 0,
      nivelActual: 0,
      enVista: true,
      countBack: 1,
      soyPep: null,
      btnActive: true,
      canDismiss: false,
      header: 0,
      footer: 0,
      cont_visible: false,
      obj_valida_basico: {},
      scroll_to_bottom: true,
      scroll_to_top: true,
      contRegEdit: {},
      blur_sup_regisFormEdit: {},
      blur_inf_regisFormEdit: {},
      variable_con_error: "",
      verificando_correo_antiguo: false,
      estado_validaciones: false,
    };
  },
  computed: {
    ...mapState([
      "infoNodos",
      "listaElementosDom",
      "remitente",
      "flujo",
      "configuracion",
      "respRemitenteModif",
      "listaElementosDomTemp",
      "procesoGeneral",
      "remitenteTemp",
      "heigthScreen",
      "widthScreen",
      "plataforma",
      "datos_consultados",
      "faltanDatos",
      "resp_send_cod_change_mail",
    ]),

    listaElementosDomOrdenada() {
      let aux = [];
      let proceso = "";
      if (this.procesoGeneral == "registro") proceso = "reg";
      if (this.procesoGeneral == "misDatos" || this.procesoGeneral == "logIn")
        proceso = "edit";
      if (this.listaElementosDom.length > 0) {
        this.configuracion.reglasFormularioRemitente.forEach(async (item) => {
          if (
            proceso != "" &&
            item[15][proceso] &&
            item[15][proceso][0] == "si"
          ) {
            this.listaElementosDom.forEach((elemento) => {
              if (elemento.variable == item[4]) {
                if (elemento.tipo == "selectDir") {
                  elemento.elementos.forEach((element) => {
                    element.editable = item[15][proceso][1];
                  });
                  // elemento.elementos[0].editable = item[15][proceso][1];
                } else {
                  if (
                    elemento.variable == "direccion" &&
                    item[15].big &&
                    item[15].big[0] == "si"
                  ) {
                    elemento.editable = item[15][proceso][1];
                    elemento.big = true;
                  } else {
                    elemento.editable = item[15][proceso][1];
                  }
                }

                aux.push(elemento);
              }
            });
          }
        });
      }
      console.log(aux);
      return aux;
    },
    textBtn() {
      //console.log(this.procesoGeneral, this.nivelActual, this.niveles);
      let aux = this.t("app.comunes.btns.continuar");
      if (
        this.nivelActual == this.niveles &&
        (this.procesoGeneral == "logIn" || this.procesoGeneral == "misDatos")
      ) {
        aux = this.t(`app.comunes.btns.completarAhora`);
      } else if (this.nivelActual == this.niveles) {
        aux = this.t("app.comunes.btns.registrarse");
      }
      return aux;
    },
    nivelesDinamico() {
      let aux = this.niveles;

      if (this.remitente.correo != this.remitenteTemp.correo) {
        aux = this.niveles + 1;
      }
      return aux;
    },

    estadoBtn() {
      let aux = "inactive";
      if (this.estado_validaciones) {
        aux = "active";
      }
      return aux;
    },
    queda_tiempo_completar_registro() {
      let aux = true;
      if (this.remitente.estado_registro == "2") {
        aux = false;
      }
      if (this.remitente.estado_registro == "3" && true) {
        //implementar fecha límite de completar registro para procesos futuros de actualización de datos
        aux = false;
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateDesde",
      "updateListaElementosDom",
      "updateInfoNodos",
      "updateBloquearNavegacion",
      "setRemitenteTemp",
      "dropRemitenteTemp",
      "updateListaElementosDomSpPropiedad",
      "updateListaElementosDomSpPropiedadDirTodo",
      "updateListaElementosDomSpPropiedadDirCero",
      "setshowSpinner",
    ]),
    ...mapActions([
      "dibujarNodosNivel",
      "crearNodoInput",
      "cambioElementoVue",
      "poblarOpcionesElementoDir",
      "tieneErroresNivelDetalle",
      "remitenteUpdate",
      "cambioElementoCheckBox",
      "cambioElementoRadioBtns",
      "send_cod_change_mail",
      "traer_comunas_v2",
      "signOff",
    ]),

    async verificar_estado_validaciones() {
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Remitente",
        id_persona: "0",
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });

      let aux = !tieneErroresNivelX.resultado;
      this.estado_validaciones = aux;
      // console.log("----");
    },

    async poblarOpcionesElementoDirLocal(data) {
      let cambioData = await this.poblarOpcionesElementoDir({
        tipo_persona: data.tipo_persona,
        variable: data.variable,
        valor: data.valor,
      });
      this.verificar_estado_validaciones();
      if (cambioData) {
        if (this.listaElementosDom[data.indiceElementoDom]) {
          this.updateListaElementosDomSpPropiedadDirTodo({
            index: data.indiceElementoDom,
            valor: cambioData.elementos,
          });
        }
      }
    },
    async cambioElementoLocal(data) {
      let nombreVariable = data.elemento.split("-")[3];
      // console.log(nombreVariable, data.valor);
      if (nombreVariable == "region") {
        let dato_existe = false;
        if (this.datos_consultados["152"]) {
          if (this.datos_consultados["152"].comuna_id) {
            if (this.datos_consultados["152"].comuna_id[`${data.valor}`]) {
              if (
                this.datos_consultados["152"].comuna_id[`${data.valor}`].status
              ) {
                dato_existe = true;
              }
            }
          }
        }
        // console.log(dato_existe, this.datos_consultados);
        if (!dato_existe) {
          this.setshowSpinner(true);
          let resp = await this.traer_comunas_v2({
            pais: "152",
            region: data.valor,
          });
          if (resp) {
            this.setshowSpinner(false);
          } else {
            this.setshowSpinner(false);
            //mostrar aviso de error / opción de reintentar
          }
        }
      }

      let cambioData = await this.cambioElementoVue({
        elemento: data.elemento,
        valor: data.valor,
        accion: data.accion,
        tipo_persona: data.tipo_persona,
        clasetienedependiente: data.clasetienedependiente,
        borrarValorHijo: true,
      });
      this.verificar_estado_validaciones();
      let elemento = data.elemento.split("-");
      if (cambioData.cargarDataAlElementoDom) {
        let index = this.listaElementosDom.findIndex((item) => {
          return (
            `slct-${item.nombre_elemento}` ==
            cambioData.dataNuevaPasada.elemento
          );
        });
        // console.log(index);
        if (index > -1) {
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "elementos",
            valor: cambioData.elementos.elementos,
          });
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "isFilledTag",
            valor: cambioData.isFilledTag,
          });
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "tieneError",
            valor: cambioData.tieneError,
          });
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "fireChange",
            valor: true,
          });
          setTimeout(() => {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "fireChange",
              valor: false,
            });
          }, 500);
        }
      } else {
        // this.listaElementosDom[data.indiceElementoDom].valor = data.valor;
        let index = this.listaElementosDom.findIndex((item) => {
          return item.variable == elemento[3];
        });

        if (index > -1 && this.listaElementosDom[index]) {
          this.updateListaElementosDomSpPropiedad({
            index: index,
            propiedad: "tieneError",
            valor: cambioData.tieneError,
          });
        }
      }
      // setTimeout(() => {
      //   console.log(this.listaElementosDom);
      // }, 500);
    },

    async cambioElementoRadioBtnsLocal(data) {
      let evaluacion = await this.cambioElementoRadioBtns(data);
      this.verificar_estado_validaciones();
      let index = this.listaElementosDom.findIndex((item) => {
        return item.variable == data.variable;
      });

      if (index > -1 && this.listaElementosDom[data.indiceElementoDom]) {
        this.updateListaElementosDomSpPropiedad({
          index: index,
          propiedad: "tieneError",
          valor: evaluacion.tieneError,
        });
      }
      // console.log(data, evaluacion, index);
    },

    cargarElementosDom() {
      this.updateListaElementosDom([]);
      this.dibujarNodosNivel({
        tipo_persona: "Remitente",
        id_persona: this.remitente.id,
        // id_persona: "0",
        nivel: this.nivelActual,
      });
    },
    async mostrar_error() {
      // console.log("mostrar_error");
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Remitente",
        id_persona: 0,
        nivel: this.nivelActual,
      });

      if (tieneErroresNivelX.resultado) {
        this.variable_con_error = "";
        tieneErroresNivelX.detalle.forEach((error) => {
          let aux = this.listaElementosDom.findIndex((elem) => {
            return elem.variable == error[1] && error[0] < 2;
          });
          // console.log(aux);
          if (aux > -1) {
            if (this.listaElementosDom[aux].tipo == "selectDir") {
              this.updateListaElementosDomSpPropiedadDirCero({
                index: aux,
                propiedad: "tieneError",
                valor: true,
              });
            }
            this.updateListaElementosDomSpPropiedad({
              index: aux,
              propiedad: "tieneError",
              valor: true,
            });

            if (this.variable_con_error == "") {
              this.scroll_to_error(error[1]);
              this.variable_con_error = error[1];
            }
          }
        });
      }
    },
    async continuarRegistro() {
      this.setshowSpinner(false);
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Remitente",
        id_persona: 0,
        nivel: this.nivelActual,
      });
      // console.log(tieneErroresNivelX);
      if (!tieneErroresNivelX.resultado) {
        if (this.nivelActual + 1 <= this.niveles) {
          this.nivelActual += 1;
          this.setshowSpinner(false);
          this.cargarElementosDom();

          setTimeout(() => {
            this.$refs["contenido_regisFormEdit"].scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 100);
        } else {
          if (this.remitente.correo != this.remitenteTemp.correo) {
            // console.log("email cambiado, mostrar confirmar correo (código de verificación");
            this.send_cod_change_mail();
          } else {
            this.remitenteUpdate();
          }
        }
      } else {
        // this.listaElementosDom.forEach((element) => {
        //   element.tieneError = false;
        // });
        this.variable_con_error = "";
        tieneErroresNivelX.detalle.forEach((error) => {
          let aux = this.listaElementosDom.findIndex((elem) => {
            return elem.variable == error[1] && error[0] < 2;
          });
          console.log(aux);
          if (aux > -1) {
            if (this.listaElementosDom[aux].tipo == "selectDir") {
              this.updateListaElementosDomSpPropiedadDirCero({
                index: aux,
                propiedad: "tieneError",
                valor: true,
              });
            }
            this.updateListaElementosDomSpPropiedad({
              index: aux,
              propiedad: "tieneError",
              valor: true,
            });

            if (this.variable_con_error == "") {
              this.scroll_to_error(error[1]);
              this.variable_con_error = error[1];
            }
          }
        });
      }
    },
    scroll_to_error(variable_con_error) {
      // console.log(
      //   variable_con_error,
      //   this.$refs[variable_con_error][0].offsetTop
      // );
      this.$refs["contenido_regisFormEdit"].scrollTo({
        top: this.$refs[variable_con_error][0].offsetTop,
        behavior: "smooth",
      });
    },
    backLocal() {
      if (this.nivelActual == 1) {
        if (this.procesoGeneral == "misDatos") {
          this.$router.push({ name: "misDatos", replace: true });
        } else if (this.procesoGeneral == "logIn") {
          console.log(
            this.procesoGeneral,
            "Controlar si queda espacio de tiempo para no completar registro faltan datos. Mostrar modal"
          );
        }
        //   this.$router.push({ name: "logIn", replace: true });
      } else {
        this.nivelActual -= 1;
        this.cargarElementosDom();
        setTimeout(() => {
          this.$refs["body_regisFormEdit"].scrollIntoView({
            block: "end",
            scrollBehavior: "smooth",
          });
        }, 100);
      }
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: data
        // },
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    async call_explicacion(tipo) {
      if (tipo == "direccion" && this.remitenteTemp.residencia == "152") return;
      let cssClass = ["myModalAbandonar"];
      if (tipo == "cargo_jerarquico") {
        if (this.widthScreen < 400) {
          cssClass.push("myModalAbandonar_ancho_90");
        } else if (this.widthScreen < 600) {
          cssClass.push("myModalAbandonar_ancho_80");
        } else if (this.widthScreen < 800) {
          cssClass.push("myModalAbandonar_ancho_70");
        } else {
          cssClass.push("myModalAbandonar_ancho_50");
        }

        if (this.heigthScreen < 700) {
          cssClass.push("myModalAbandonar_alto_90");
        } else if (this.heigthScreen < 900) {
          if (this.widthScreen < 400) {
            cssClass.push("myModalAbandonar_alto_90");
          } else {
            cssClass.push("myModalAbandonar_alto_70");
          }
        } else if (this.heigthScreen < 1200) {
          cssClass.push("myModalAbandonar_alto_60");
        } else {
          cssClass.push("myModalAbandonar_alto_60");
        }
      } else if (tipo == "direccion") {
        if (this.widthScreen < 400) {
          cssClass.push("myModalAbandonar_ancho_70");
        } else if (this.widthScreen < 600) {
          cssClass.push("myModalAbandonar_ancho_50");
        } else if (this.widthScreen < 800) {
          cssClass.push("myModalAbandonar_ancho_40");
        } else {
          cssClass.push("myModalAbandonar_ancho_40");
        }

        if (this.widthScreen < 400) {
          cssClass.push("myModalAbandonar_alto_60");
        } else if (this.heigthScreen < 800) {
          cssClass.push("myModalAbandonar_alto_50");
        } else if (this.heigthScreen < 900) {
          cssClass.push("myModalAbandonar_alto_40");
        } else if (this.heigthScreen < 1200) {
          cssClass.push("myModalAbandonar_alto_40");
        } else {
          cssClass.push("myModalAbandonar_alto_40");
        }
      }

      let titulo = "";
      let texto = "";
      if (tipo == "cargo_jerarquico") {
        titulo = this.t("app.comunes.cargo_jerarquico");
        texto = this.t("app.registroFormInicial.pep_descripcion");
      } else if (tipo == "direccion") {
        titulo = this.t("app.comunes.direccion");
        texto = this.t("app.registroFormInicial.direccion");
      }
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: explicacion,
        componentProps: {
          title: titulo,
          color_title: "azul",
          color_btn_1: "azul",
          color_btn_2: "gris",
          text: texto,
          btnText_1: this.t("app.comunes.btns.entiendo"),
          btnText_2: "",
          colorIcon: "rojoOscuro",
        },
        cssClass: cssClass,

        animated: true,
        backdropDismiss: false,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    valida_basico(data) {
      this.verificar_estado_validaciones();
      // console.log(data.variable);
      this.obj_valida_basico[data.variable] = data.valida;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },

    async callAbandonar() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.recoverPassForm.tit_abandonar"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "gris",
          text: "",
          btnText_1: this.t("app.comunes.btns.salir"),
          btnText_2: "",
          colorIcon: "rojoOscuro",
        },
        cssClass:
          this.widthScreen < 600
            ? [
                "myModalAbandonar",
                "myModalAbandonar_ancho_80",
                "myModalAbandonar_alto_50",
              ]
            : [
                "myModalAbandonar",
                "myModalAbandonar_ancho_40",
                "myModalAbandonar_alto_40",
              ],
        // showBackdrop: false,
        animated: true,
        backdropDismiss: false,
        // swipeToClose: true,
        // mode: "ios",
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          console.log(this.procesoGeneral);
          if (this.queda_tiempo_completar_registro) {
            if (this.procesoGeneral == "misDatos") {
              this.$router.push({ name: "misDatos", replace: true });
            } else if (this.procesoGeneral == "logIn") {
              this.$router.push({ name: "home", replace: true });
            } else {
              console.log("Inconsistencia!!!!!!!!!!!!!!!!!!!!!");
            }
          } else {
            this.signOff();
            this.$router.push({ name: "logIn", replace: true });
          }
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });
      return modal.present();
    },
    async presentAlert(data) {
      let alert = await alertController.create({
        header: data.title,
        subHeader: data.msg,
        buttons: ["Ok"],
        backdropDismiss: false,
      });
      await alert.present();
    },
  },
  watch: {
    async infoNodos() {
      if (this.infoNodos.length > 0 && this.enVista) {
        // console.log(this.infoNodos);
        for (const element of this.infoNodos) {
          await this.crearNodoInput({
            elementoDom: element.elementoDom,
            tipo_persona: element.tipo_persona,
            id_persona: element.id_persona,
            variable: element.variable,
            pais: element.pais,
          });
        }
        this.updateListaElementosDom(this.listaElementosDomTemp);
      }
    },
    async respRemitenteModif() {
      if (this.respRemitenteModif.status && this.enVista) {
        this.setshowSpinner(false);
        if (this.respRemitenteModif.resp && this.enVista) {
          if (this.procesoGeneral == "logIn") {
            this.$router.push({ name: "home", replace: true });
          } else {
            this.$router.push({ name: "misDatos", replace: true });
          }
        } else if (this.enVista) {
          console.log(
            "Error al registrar cambios: ",
            this.respRemitenteModif.msg
          );
          this.presentAlert({
            title: "Error",
            msg: this.t(
              `configuracion.errores_srv.Cliente.${this.respRemitenteModif.msg}`
            ),
          });
        }
      }
    },
    resp_send_cod_change_mail() {
      if (this.resp_send_cod_change_mail.status && this.enVista) {
        if (this.resp_send_cod_change_mail.resp) {
          this.verificando_correo_antiguo = true;
          this.setshowSpinner(false);
          this.$router.push({
            name: "registroEditAutentificacion",
            replace: true,
          });
        } else {
          this.presentAlert({
            title: "Error",
            msg: this.t(
              `configuracion.errores_srv.Cliente.${this.respRemitenteModif.msg}`
            ),
          });
        }
      }
    },
    heigthScreen() {
      this.contRegEdit = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  created() {
    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
    this.niveles = Math.max.apply(
      Math,
      this.configuracion.reglasFormularioRemitente.map((campo) => {
        return campo[3];
      })
    );
    this.nivelActual = 1;
  },
  mounted() {},
  async ionViewWillEnter() {
    this.enVista = true;
    this.updateDesde("registroFormulario");
    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
    this.setRemitenteTemp();
    this.cargarElementosDom();
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_regisFormEdit"].offsetHeight;
        this.footer = this.$refs["footer_regisFormEdit"].offsetHeight;
        this.cont_visible = true;
        this.contRegEdit = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_regisFormEdit = blur_sup_style(this.header);
        this.blur_inf_regisFormEdit = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_regisFormEdit"].scrollHeight >
          this.$refs["contenido_regisFormEdit"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });

    // this.$nextTick(() => {
    //   this.header = this.$refs[""].offsetHeight;
    //   this.footer = this.$refs[""].offsetHeight;
    //   this.cont_visible = true;
    // });
  },
  ionViewWillLeave() {
    this.cont_visible = false;
    this.enVista = false;
    if (!this.verificando_correo_antiguo) {
      this.dropRemitenteTemp();
    }

    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
  },
};
</script>

<style>
.regisFormEditTitle {
  margin-top: 1rem;

  color: var(--customA-801B1C);
  text-align: center;
}
.regisFormEditInputCont {
  margin-top: 0.8rem;
}
.regisFormEditHr {
  margin-top: 0.45rem;
  margin-bottom: 1.25rem;
}
.regisFormEditActionBtn {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin: 2rem 0 0 0;
}
.body_regisFormEdit_class {
  /* border: 1px solid green;
  height: 100%;
  overflow-y: auto; */
}
</style>